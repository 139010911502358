import * as React from "react";

import tothelist from "../images/tothelist.webp";
import seaturtle from "../images/seaturtle.webp";
import bff_medium from "../images/bff-medium.webp";
import go_grpc from "../images/gogrpc.webp";
import kotlin_grpc from "../images/kotlingrpc.webp";
import java_grpc from "../images/javagrpc.webp";
import grpc_web_demo from "../images/grpc_web_demo.webp";
import microapps from "../images/microapps.webp";

import { Icon, Popup, Label } from "semantic-ui-react";

const platform_icons = {
  medium: "medium",
  github: "github",
  deployed: "cloud",
};

const platforms = {
  medium: (
    <Popup
      trigger={
        <Label
          attached="top right"
          icon={<Icon name={platform_icons.medium} fitted />}
          color="teal"
        />
      }
    >
      Available on Medium
    </Popup>
  ),
  github: (
    <Popup
      trigger={
        <Label
          attached="top right"
          icon={<Icon name={platform_icons.github} fitted />}
          color="purple"
        />
      }
    >
      Available on GitHub
    </Popup>
  ),
  deployed: (
    <Popup
      trigger={
        <Label
          attached="top right"
          icon={<Icon name={platform_icons.deployed} fitted />}
          color="orange"
        />
      }
    >
      Deployed on Cloud
    </Popup>
  ),
};

export const content = {
  //   Header Details ---------------------
  header: "Oggy",
  subHeader: ["👨🏻‍💻", "🏠🇸🇪", "💬🇬🇧"],
  intro: <span></span>,
  introStack: [
    "Java & Spring",
    "Go",
    "Python",
    "JavaScript & React",
    "AWS & GC",
    "gRPC & REST & GraphQL",
    "DDD",
    "Software Architecture",
  ],
  about: (
    <p>
      I love designing and developing cloud-based solutions
      <b> in any domain</b>
      <br />I have great focus on value creation
    </p>
  ),
  contactEmail: "uid4oe@gmail.com",
  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: `BFF Pattern in Go Microservices`,
      platform: platforms.medium,
      platform_icon: platform_icons.medium,
      description: `Brief explanation of the BFF Pattern and showcasing it's usage in a distirubuted system. Covering gRPC service and client implementation in-depth with the Go version. Exact use case is implemented in both Java and Kotlin.
       `,
      image: bff_medium,
      url: "https://itnext.io/bff-pattern-with-go-microservices-using-rest-grpc-87d269bc2434",
    },
    {
      title: "Building Microapps with gRPC-Web",
      description: `Could teams that are performing end-to-end development boost organizational efficiency??? Details on how gRPC-Web could be used to enable such transformation with step by step implementation in Go and React.`,
      platform: platforms.medium,
      platform_icon: platform_icons.medium,
      image: microapps,
      url: "https://medium.com/alva-labs/building-microapps-with-grpc-web-64b7cdf50313",
    },
    {
      title: "gRPC-Web Demo with Go & React",
      description: `A complete full-stack flow showcasing gRPC-Web usage in between services and UI. Implementation examples for all the available gRPC service method types could be found. Used Go and React.`,
      image: grpc_web_demo,
      platform: platforms.deployed,
      platform_icon: platform_icons.deployed,
      url: "https://grpc-web.uid4oe.dev/",
    },
    {
      title: "Microservices Go gRPC",
      description: `Go microservices with REST, and gRPC using BFF pattern.Includes detailed setup guide.
      Everything is dockerized and ready to "Go" : -), Link will direct you to
      deployed instance.`,
      platform: platforms.deployed,
      platform_icon: platform_icons.deployed,
      image: go_grpc,
      url: "https://go-grpc.uid4oe.dev",
    },
    {
      title: "Drone Surveilance for Sea Turtles",
      description: `System is designed to perform drone based missions and auto - detect Sea Turtles nets,
  through out the mission execution using AutoML.Also, system is supporting real - time tracking
         and provide rich functionality with Google Maps integartion & customization.`,
      image: seaturtle,
      platform: platforms.github,
      platform_icon: platform_icons.github,
      url: "https://github.com/uid4oe/seaturtle-web",
    },
    {
      title: "To The List",
      description: `To The List allows you to make any type of lists.
  Browse, edit, share, add to your library etc. 
        Used React.js with Semantic UI, and Firebase for BaaS.
        `,
      image: tothelist,
      platform: platforms.deployed,
      platform_icon: platform_icons.deployed,
      url: "http://list.uid4oe.dev/",
    },

    {
      title: "Microservices Kotlin Spring gRPC",
      description: `Kotlin Spring microservices with REST, and gRPC using BFF pattern.  
      Everything is dockerized.Link will refer to Github.Deployed version is offline,
  as the deployed Go version provides the same functionality.`,
      image: kotlin_grpc,
      platform: platforms.github,
      platform_icon: platform_icons.github,
      url: "https://github.com/uid4oe/microservices-kotlin-grpc",
    },
    {
      title: "Microservices Java Spring gRPC",
      description: `Java Spring microservices with REST, and gRPC using BFF pattern.  
        Everything is dockerized.Link will refer to Github.Deployed version is offline,
  as the deployed Go version provides the same functionality.`,
      image: java_grpc,
      platform: platforms.github,
      platform_icon: platform_icons.github,
      url: "https://github.com/uid4oe/microservices-java-grpc",
    },
  ],
  // End Work Section -----------------------
};

const data = () => {
  return <></>;
};
export default data;
